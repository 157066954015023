export const imageUploader = (element) => {
    element.addEventListener('change', (e) => {
        var form_data = new FormData(),
            unit_id = document.querySelector('#unity_id').getAttribute('value'),
            action = element.getAttribute('data-action'),
            file_data = e.target.files[0];

        form_data.append("image", file_data);
        form_data.append("unit_id", unit_id);
        post(action, form_data);
    })
}


export const imageRemover = (element) => {
    element.addEventListener('click', (e) => {
        var form_data = new FormData(),
            action = element.getAttribute('data-action'),
            image_id = element.getAttribute('data-image-remove');

        form_data.append("image_id", image_id);
        post(action, form_data);
    })
}

const post = (action, form_data) => {
    fetch(action, {
        body: form_data,
        method: "post",
        headers: {
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        }
    })
    .then((res) => res.json())
    .then((data) => {
        console.info('post return', data);
        alert(data.message);
        window.location.reload();
    });
}