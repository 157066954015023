export const videoUploader = (element) => {
    element.addEventListener('change', (e) => {
        var form_data = new FormData(),
            unit_id = document.querySelector('#unity_id').getAttribute('value'),
            action = element.getAttribute('data-action'),
            file_data = e.target.files[0];

        form_data.append("video", file_data);
        form_data.append("unit_id", unit_id);

        post(action, form_data, element);
    })
}


export const videoRemover = (element) => {
    element.addEventListener('click', (e) => {
        var form_data = new FormData(),
            action = element.getAttribute('data-action'),
            content_id = element.getAttribute('data-content-remove');

        form_data.append("content_id", content_id);
        post(action, form_data, element);
    })
}

const post = (action, form_data, element) => {
    console.log('post > element', element);
    
    var label = document.querySelector('.videoUploader_label');
    if (label) {
        label.innerHTML = "Carregando...";
    }

    element.disabled = true;

    fetch(action, {
        body: form_data,
        method: "post",
        headers: {
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        }
    })
    .then((res) => res.json())
    .then((data) => {
        console.info('post return', data);
        alert(data.message);
        window.location.reload();
    })
    .catch((err) => {
        console.warn('post ERROR', err);
    });
}